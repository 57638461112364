<!--
  PACKAGE_NAME : src\pages\esp\system\holiday\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 공휴일 관리
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="onInitNewRow" />
  </div>
</template>

<script>
  import { isSuccess, getPastFromToday } from '@/plugins/common-lib';
  import moment from 'moment';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid },
    data() {
      return {
        dataGrid: {
          id: 'holidayId',
          refName: 'holidayGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          apiActionNm: {
            merge: 'HOLIDAY_LIST_SAVE',
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteData();
                  },
                },
                location: 'before',
              },
            ], // 그리드 커스텀 버튼 생성
          },
          customEvent: {
            initNewRow: true,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '유형',
              dataField: 'holidayTypeCd',
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.holidayType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              requiredRule: {
                message: '유형은 필수입니다.',
              },
            },
            {
              caption: '공휴일명',
              dataField: 'holidayNm',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '공휴일명은 필수입니다.',
              },
            },
            {
              caption: '일자',
              dataField: 'holidayTm',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              dataType: 'date',
              format: 'yyyy.MM.dd',
              cellTemplate: (container, options) => {
                const dateValue = moment(options.value, 'YYYYMMDD');
                const condition = options.data.holidayTypeCd === this.$_enums.common.holidayType.FIXED.value;
                const formattedDate = condition ? dateValue.format('MM.DD') : dateValue.format('YYYY.MM.DD');
                container.append(formattedDate);
              },
              sortOrder: 'none',
              allowHeaderFiltering: false,
              requiredRule: {
                message: '일자는 필수입니다.',
              },
            },
            {
              caption: '등록자',
              i18n: 'COMPONENTS.REGISTRANT',
              dataField: 'regId',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록일시',
              i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
              dataField: 'regDt',
              dataType: 'date',
              format: 'yyyy-MM-dd HH:mm:ss',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정자',
              i18n: 'COMPONENTS.MODIFIER',
              dataField: 'editId',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정 일시',
              i18n: 'COMPONENTS.MODIFY_DATE_TIME',
              dataType: 'date',
              dataField: 'editDt',
              format: 'yyyy-MM-dd HH:mm:ss',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description: 그리드 새 행 초기 셋팅 이벤트 */
      onInitNewRow(e) {
        //일자 셋팅
        e.data.holidayTm = getPastFromToday(0, 'days');
      },
      /**
       * @description: 공휴일 조회
       * @returns {{data, totalCount}}
       */
      selectDataList() {
        const vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'holidayId',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);
            const payload = {
              actionname: 'HOLIDAY_LIST_ALL',
              data: { params },
              useErrorPopup: true,
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };
            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },
      /**
       * @description: 공휴일관리 삭제
       * @returns {Promise<void>}
       */
      async onDeleteData() {
        const selectedRowsData = this.$refs.holidayGrid.getGridInstance.getSelectedRowsData();
        if (selectedRowsData?.length) {
          if (await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
            const payload = {
              actionname: 'HOLIDAY_LIST_DELETE',
              data: {
                data: selectedRowsData.map(item => item.holidayId),
              },
              useErrorPopup: true,
            };

            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              this.$refs.holidayGrid.refreshData();
            }
          }
        } else {
          this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }
      },
    },
    created() {},
    mounted() {
      this.selectDataList();
    },
  };
</script>

<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }

  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }

  a {
    color: #545454;
  }
</style>
